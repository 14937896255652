import React from 'react'
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Section, BreadCrumb, MarkdownContent, MarkdownContentWrapper, MarkdownContentWrapperInner, MarkdownContentTitle, ComingSoon } from '../components/Section'
import PrimaryLinkButton from '../components/Button/PrimaryLinkButton'
import { Link } from "gatsby"
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'
import BreakpointDown from '../components/Media/BreakpointDown'


const GroceryTitle = styled.h1`

`
const GroceryRow = styled.div`
  display:flex;
  margin-left:-15px;
  margin-right:-15px;
  flex-wrap:wrap;
  justify-content:space-between;
  margin-top:20px;
`
const GroceryColumn = styled.div`
 flex:0 0 50%;
 width:50%;
 padding-left:15px;
 padding-right:15px;
 ${BreakpointDown.xl`
  flex:0 0 100%;
  width:100%; 
 `}
 .gatsby-image-wrapper{ width:100%;}
 &:first-child{
   padding-right:60px;
   ${BreakpointDown.xl`
      flex:0 0 100%;
      width:100%;
      padding-right:15px;
    `}
 }
 &:last-child{
   padding-top:40px;
   ${BreakpointDown.md`
     padding-top:0px;
   `}
 }
`


// GroceryIndustry
const SectionBoxShadow = styled(Section)`
  box-shadow: 0px 20px 85px #00000033;
  z-index:2;
`
const GroceryIndustryTitle = styled.h2`
  max-width: 768px;
  text-align:center;
  margin-left:auto;
  margin-right:auto;
`

const GroceryIndustryList = styled.div`
 display:flex;
 flex-wrap:wrap;
 margin-top:60px;
 border-bottom:1px solid #CCCCCC;
 padding-bottom:60px;
 ${BreakpointDown.xl`
   margin-top:30px;
   padding-bottom:30px;
 `}
 &:nth-child(odd){
   flex-direction:row-reverse;
   &:last-child{border-bottom:0; padding-bottom:0;}
   .industry-left{
  &:before{
    right:auto;
    margin-right:0;
    left: 0;
    top: 35px;
    margin-left: -60px; 
  }
   }
   .industry-right{
    padding-right: 90px;
    padding-left:0;
    ${BreakpointDown.xl`
      padding-right: 0px;
    `}
   }
 }
`
const GroceryIndustryListLeft = styled.div`
flex:0 0 600px;
position:relative;
${BreakpointDown.xl`
 flex:0 0 100%;
`}
&:before{
  content:'';
  width:120px;
  height:4px;
  background:#000;
  position:absolute;
  right:0;
  top:35px;
  margin-right:-60px;
  z-index:1;
  ${BreakpointDown.xl`
     display:none;
  `}
}
`
const GroceryIndustryListRight = styled.div`
  flex:1;
  padding-left:90px;
  padding-top:20px;
  ${BreakpointDown.xl`
    padding-left: 0px;
  `}
    ${BreakpointDown.md`
     padding-top:10px;
  `}
`
const GroceryIndustryListRightTitle = styled.div`
${BreakpointDown.md`
  margin-bottom:0;
`}
`
const GroceryIndustryListRightContent = styled.div`
 p{
   font-style:normal;
 }
`
const TraingleShape = styled.div`
  width: 0;
  height: 0;
  display: block;
  border-top: 20px transparent solid;
  border-right: 20px transparent solid;
  border-left: 20px transparent solid;
  border-bottom: 20px #fff solid;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -1px;
  margin-left: auto;
  margin-right: auto;
  z-index: 3;
`


function ConvenienceStoresPage() {
  return (
    <Layout pageName="industry">
      <SEO
        title="Technology Solutions for Convenience Store across the USA"
        description="WYN Technologies offers Business Technology solutions to Convenience Stores of all sizes across the states. Contact us to get a quote for Access control, POS, and security camera solutions."/>
      <Section pt="174px" pb="80px" bgColor="#F8F8F8" xpt="120px" xpb="60px" mpt="90px" mpb="40px">
        <TraingleShape />
        <div className="container">
          <BreadCrumb>
            <Link to="/">Home</Link><Link to="/#">Convenience Stores</Link>
          </BreadCrumb>
          <GroceryRow>
            <GroceryColumn>
              <GroceryTitle>Business Technology Solutions for Convenience Stores</GroceryTitle>
              <p>By all rights, the convenience store (or C-store) industry is big business in America. As of 2020, there were over 150,000 convenience stores spread across the U.S., with the majority of those being independent stores. Most of a convenience store’s business comes through the sale of motor fuel, but as we all know that’s not your real money maker. Because convenience stores are so convenient, they can serve as one-stop shops for everything from snacks, to drinks, to groceries, to automotive supplies, and all sorts of other items.</p>
              <p>Another thing that’s true about C-stores is that they have their own respective business technology needs, too. If you’re a C-store business owner or manager, <strong>WYN Technologies</strong> provides the technology solutions you really need!</p>
            </GroceryColumn>
            <GroceryColumn>
              <StaticImage
                src="../images/industry-1.jpg"
                quality={100}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Brands We Trust"
                placeholder="blurred"
              />
            </GroceryColumn>
          </GroceryRow>
        </div>
      </Section>
      <SectionBoxShadow bgColor="#F8F8F8" pt="80px" pb="80px" xpt="60px" xpb="60px" mpt="40px" mpb="40px">
        <div className="container">
          <GroceryIndustryTitle className="h1">Some of the Services We Offer for Individual C-Stores and Convenience Store Chains</GroceryIndustryTitle>
          <GroceryIndustryList>
            <GroceryIndustryListLeft className="industry-left">
              <StaticImage
                src="../images/placholder.jpg"
                quality={100}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Industry"
                placeholder="blurred"
              />
            </GroceryIndustryListLeft>
            <GroceryIndustryListRight className="industry-right">
              <GroceryIndustryListRightTitle className="h3">Digital Signage Installation</GroceryIndustryListRightTitle>
              <GroceryIndustryListRightContent>
                <p>Digital signage can benefit a convenience store in many ways. Outdoor digital signs are a great way to draw more attention to your business. Indoor signs can be used to deliver important product or store information. In addition, digital signs have been shown to help generate greater sales boosts than static, printed signage. And interactive touchscreen signs can be utilized by customers to place their own food orders either inside your snack area, or outside in the drive-thru lane. <strong>WYN</strong> can install whatever you need!</p>
                {/* <Link to="/"><PrimaryLinkButton text="Learn More"></PrimaryLinkButton></Link> */}
              </GroceryIndustryListRightContent>
            </GroceryIndustryListRight>
          </GroceryIndustryList>
          <GroceryIndustryList>
            <GroceryIndustryListLeft className="industry-left">
              <StaticImage
                src="../images/placholder.jpg"
                quality={100}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Industry"
                placeholder="blurred"
              />
            </GroceryIndustryListLeft>
            <GroceryIndustryListRight className="industry-right">
              <GroceryIndustryListRightTitle className="h3">Drive-Thru Technology Installations</GroceryIndustryListRightTitle>
              <GroceryIndustryListRightContent>
                <p>Since convenience stores are meant to be convenient, adding a drive-thru lane really makes a lot of sense. Some keys to success include giving careful thought to the drive-thru design process for your C-store, deciding which items your drive-thru should offer, and staffing it properly. And the other key to drive-thru success is utilizing the correct technology. <strong>WYN Technologies</strong> can help you with digital signage, audio intercoms, video surveillance, POS system solutions, and much more.</p>
                {/* <Link to="/"><PrimaryLinkButton text="Learn More"></PrimaryLinkButton></Link> */}
              </GroceryIndustryListRightContent>
            </GroceryIndustryListRight>
          </GroceryIndustryList>
          <GroceryIndustryList>
            <GroceryIndustryListLeft className="industry-left">
              <StaticImage
                src="../images/placholder.jpg"
                quality={100}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Industry"
                placeholder="blurred"
              />
            </GroceryIndustryListLeft>
            <GroceryIndustryListRight className="industry-right">
              <GroceryIndustryListRightTitle className="h3">POS System Installations</GroceryIndustryListRightTitle>
              <GroceryIndustryListRightContent>
                <p>And speaking of point-of-sale, every convenience store needs to be able to ring up customers in as effective and efficient a manner as possible. That includes good pay-at-the-pump technology outside, as well as touchscreen registers that are designed to be both intuitive and painless for your employees inside. And if you’re looking for the best POS system installation solutions, <strong>WYN</strong> has you covered!</p>
                {/* <Link to="/"><PrimaryLinkButton text="Learn More"></PrimaryLinkButton></Link> */}
              </GroceryIndustryListRightContent>
            </GroceryIndustryListRight>
          </GroceryIndustryList>
          <GroceryIndustryList>
            <GroceryIndustryListLeft className="industry-left">
              <StaticImage
                src="../images/placholder.jpg"
                quality={100}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Industry"
                placeholder="blurred"
              />
            </GroceryIndustryListLeft>
            <GroceryIndustryListRight className="industry-right">
              <GroceryIndustryListRightTitle className="h3">WiFi Networks & Access Points</GroceryIndustryListRightTitle>
              <GroceryIndustryListRightContent>
                <p>WiFi access points are no longer simply an optional convenience to offer; they’ve really become a necessity for modern businesses, C-stores included. Your customers have come to expect it, & much of your business technology is likely linked to it. And the best news? <strong>WYN Technologies</strong> can install it for you, and we can even troubleshoot your current wireless network with our state-of-the-art heat mapping services.</p>
                {/* <Link to="/"><PrimaryLinkButton text="Learn More"></PrimaryLinkButton></Link> */}
              </GroceryIndustryListRightContent>
            </GroceryIndustryListRight>
          </GroceryIndustryList>
          <GroceryIndustryList>
            <GroceryIndustryListLeft className="industry-left">
              <StaticImage
                src="../images/placholder.jpg"
                quality={100}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Industry"
                placeholder="blurred"
              />
            </GroceryIndustryListLeft>
            <GroceryIndustryListRight className="industry-right">
              <GroceryIndustryListRightTitle className="h3">VoIP Phone Systems</GroceryIndustryListRightTitle>
              <GroceryIndustryListRightContent>
                <p>There are times you’ll need to make a business call, and you’d certainly like for customers to be able to reach your convenience store by phone, too. There are lots of reasons to go with a VoIP-based phone system. You’ll also many features with VoIP that you simply can’t get otherwise, and you can save a good bit of money, too. Connect with the pros at <strong>WYN</strong> today, and let us help you find your best business communications solution!</p>
                {/* <Link to="/"><PrimaryLinkButton text="Learn More"></PrimaryLinkButton></Link> */}
              </GroceryIndustryListRightContent>
            </GroceryIndustryListRight>
          </GroceryIndustryList>
        </div>
      </SectionBoxShadow>
      <Section pt="100px" pb="100px" xpt="60px" xpb="60px" mpt="40px" mpb="40px" bgColor="#F8F8F8">
        <div className="container">
          <MarkdownContentWrapper>
            <MarkdownContentWrapperInner>
              <MarkdownContentTitle>
                {/* <h2 className="h1">Access Control Technology for C-Stores</h2> */}
              </MarkdownContentTitle>
              <MarkdownContent>
                <h2>Access Control Technology for C-Stores</h2>
                <p>Access control systems are beneficial in addressing many convenience store security and risk issues. From limiting C-store points of entry, to controlling which employees have access to particular facilities, C-store owners and managers have many things to prioritize when it comes to managing building access control. A well-designed access control system can help to reduce the risk of theft, and can also be used to support current best practices in inventory control.</p>
                <p>Commercial access control systems can be utilized to help govern everything from restroom access, to freezer and storeroom access, and can even be operated remotely. Choosing to install the right access control system can make your job easier as a convenience store business leader, and can also provide some needed peace of mind when it comes to knowing exactly who has access to which parts of your business. Looking for a good provider of access control system installations for businesses? You’ve found one in <strong>WYN Technologies</strong>!</p>
              </MarkdownContent>
              <MarkdownContent>
                <h2>Choose WYN Technologies for All Your Convenience Store IT & Business Technology Needs</h2>
                <ul>
                  <li>
                    <strong>Tested-and-Approved Custom Solutions for You</strong>
                    <p>At WYN, we’re specially equipped to be able to fully stage, configure, and test your chosen devices and products ahead of time in our dedicated staging facility, before we ever come out to install your new equipment.</p>
                  </li>
                  <li>
                    <strong>Only the Highest-Quality Products & Services</strong>
                    <p>WYN Technologies only partners with the best business technology brands and manufacturers. And our level of installation workmanship and ongoing personalized service for our clients is really second to none!</p>
                  </li>
                  <li>
                    <strong>We Know How to Work Within Your Budget</strong>
                    <p>WYN is ultimately here to help your business succeed. We understand corporate budgets & finances, and we can deliver the best technological solution to meet both your needs and your budget!</p>
                  </li>
                  <li>
                    <strong>Fast, Responsive 24/7 Customer Support</strong>
                    <p>Unlike some other technology providers, WYN is committed to be here for you for the long run. We actually answer the phone when you call, and we’ll respond promptly to your needs 24/7.</p>
                  </li>
                </ul>
              </MarkdownContent>
              <MarkdownContent>
                <h2>Got Questions? Ready to Get Started?</h2>
                <p>Need to plan some business technology upgrades for your C-store facility, or for multiple locations? WYN Technologies is happy to come out and deliver a free initial site evaluation and consultation. Got some specific questions? Ready to get started? Connect with us today at <a href="tel:3368990555">(336) 899-0555</a>, and let’s make a plan together!</p>
              </MarkdownContent>
            </MarkdownContentWrapperInner>
            </MarkdownContentWrapper>
  </div>
      </Section>
    </Layout>
      )
}
      export default ConvenienceStoresPage
